.request-loader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: #ffffff00;
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.25);
    overflow: visible; /* Permitir que a animação se expanda além das bordas */
  }
  
  .request-loader span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
    z-index: 2; /* Garante que o texto esteja à frente */
  }
  
  .request-loader::after,
  .request-loader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 8px solid rgba(255, 255, 255, 0.055);
    border-radius: 50%;
    z-index: 0; /* Garante que o pseudoelemento fique atrás */
    animation: ripple 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
    transform: scale(1); /* Iniciar no tamanho das bordas do círculo principal */
  }
  
  .request-loader::before {
    animation-delay: 0.5s;
  }
  
  @keyframes ripple {
    0% {
      opacity: 1;
      transform: scale(1); /* Iniciar no tamanho exato do círculo principal */
    }
    100% {
      opacity: 0;
      transform: scale(1.75); /* Expande além das bordas para dar o efeito de crescimento */
    }
  }
  