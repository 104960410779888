.m-ticket {
    width: auto;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 60px;
    margin-top: -30px;
    margin-bottom: -20px;
}

.m-linhas {
    display: flex;
    gap: 5px;
    margin-top: 28px;
    /* Espaçamento entre as bolinhas */
}

.m-linhas span {
    width: 18px;
    height: 10px;
    background-color: #ffffff;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}


.m-ticket:before {
    content: "";
    position: absolute;
    left: -10px;
    top: 41%;
    background: #eee;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    width: 20px;
    height: 17px;
    border-radius: 50%;
}


.m-ticket:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 41%;
    background: #eee;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    width: 20px;
    height: 17px;
    border-radius: 50%;
}
