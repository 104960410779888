.custom-tooltip-class {
  background-color: #f5f5f5; /* Altere a cor de fundo */
  color: rgba(0, 0, 0, 0.456); /* Altere a cor do texto */
  padding: 15px;
  font-size: 14px;
  border-radius: 16px;
  font-family: "Open Sans", sans-serif;
}

.custom-step-text {
  padding: 15px;
}

.custom-step-text-typography1 {
  margin: 0;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.25rem;
  margin-top: 8px;
  margin-bottom: 8px;
}

.custom-step-text-body1 {
  margin: 0;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
}

.custom-back-button,
.custom-next-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  line-height: 1.7142857142857142;
  font-size: 0.9375rem;
  text-transform: unset;
  min-width: 32px;
  padding: 8px 22px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: #31a552;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  text-transform: none;
  font-weight: 600;
  border-color: #d6d6d6;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-back-button:hover,
.custom-next-button:hover {
  background-color: #ec971f; /* Alteração na cor ao passar o mouse */
}

.highlighted-element {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Destaque no elemento */
}

@keyframes colorWave {
  0% {
    color: #31a552;
  }
  20% {
    color: #0954aa;
  }
  40% {
    color: #43a047;
  }
  60% {
    color: #3540bb;
  }
  100% {
    color: #43a047;
  }
}

.color-wave-icon {
  animation: colorWave 3s infinite;
}
