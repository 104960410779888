.leaflet-container {
    height: 100%;
    width: 100%;
}

.chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #f1f1f1;
}

.chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.static-map {
    width: 250px;
    height: 250px;
}

.leaflet-right {
    display: none;
}

.MuiAppBar-colorDefault {
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff !important;
}

.PrivateTabIndicator-colorPrimary-250 {
    background-color: #c62828 !important
}
.PrivateTabIndicator-colorPrimary-137 {
    background-color: #c62828 !important
}

.PrivateTabIndicator-colorPrimary-153 {
    background-color: #c62828 !important;
}

.PrivateTabIndicator-colorPrimary-175 {
    background-color: #c62828 !important;
}

.MuiPaper-elevation4 {
    box-shadow: 0px 0px 0px 0px;
}

.MuiPaper-elevation1{
    box-shadow: 0px !important;
}