#BoxConfDesk {
    min-height: calc(100vh - 68.51px); /*69*/
}
#BoxNotConfDesk {
    min-height: calc(100vh - 120.6px); /**/
}

a, a:focus {
    outline: none;
    text-decoration: none;
}
